<template>
    <div class="content" v-loading="loading" element-loading-text="生成订单中，请稍后...">
        <!-- 表格内容 -->
        <commonTitle></commonTitle>
        <!--        选择小区-->
        <div class="create_header">
            <ul>
                <li v-for="(item,index) in villageData" :key="index" @click="checkVillageItem(index,item)"
                    :class="{active:item.checked}">
                    {{item.villageName}}
                </li>
            </ul>
            <div class="create_btn">
                <el-button type="warning" @click="checkVillage(1)">全选</el-button>
                <el-button type="info" @click="checkVillage(2)">取消</el-button>
                <el-button type="info" @click="back">返回</el-button>
            </div>
        </div>
        <!--        选择楼幢-->
        <div class="create_header" v-show="towerData.length!=0">
            <ul>
                <li v-for="(item,index) in towerData" :key="index" @click="check(index,item.towerId,item)"
                    :class="{active:item.bon}">
                    {{item.towerNum}}
                </li>
            </ul>
            <div class="create_btn">
                <el-button type="warning" @click="checkALl" size="small">全选</el-button>
                <el-button type="info" @click="reverseAll" size="small">取消</el-button>
            </div>
        </div>
        <div class="content_main">

            <!-- 展示单元-->
            <div class="create_content">
                <ul v-show="unitData.length!=0">
                    <h5>单元数据</h5>
                    <div>
                        <li v-for="(item,index) in unitData" @click="checkHS(index)" :key="index"
                            :class="{active1:item.checked}">
                            <p>{{item.unitName}}</p>
                        </li>
                    </div>
                </ul>
                <div class="create_btn" v-show="unitData.length!=0">
                    <el-button type="warning" @click="unitCheckALl" size="small">全选</el-button>
                    <el-button type="info" @click="unitReverseAll" size="small">取消</el-button>
                </div>
                <ul v-show="houseData.length!=0">
                    <h5>户室数据</h5>
                    <div>
                        <li v-for="(item,index) in houseData" @click="tabHS(index)" :key="index"
                            :class="{active1:item.checked}">
                            <p>{{item.houseNum}}</p>
                        </li>
                    </div>
                </ul>
                <div class="create_btn" v-show="houseData.length!=0">
                    <el-button type="warning" @click="houseCheckALl" size="small">全选</el-button>
                    <el-button type="info" @click="houseReverseAll" size="small">取消</el-button>
                    <el-button type="success" @click="send()" size="small" :disabled="dis">新增订单</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import commonTitle from "../commonTitle";

    export default {
        name: "checkHouse",
        components: {
            commonTitle
        },
        data() {
            return {
                url: this.$Config.base_server,
                form: {},
                loading: false,
                villageData: [],//小区数据
                towerData: [],//楼幢数据
                unitData: [],//单元数据
                houseData: [],//户室数据

                towerIds: [],//选中的楼幢id 数组
                unitIds: [],//选中的单元Id数组
                dis: false
            }
        },
        mounted() {
            this.checkXiaoQu();
        },
        methods: {
            checkVillageItem(index, item) {//点击小区查楼撞
                this.villageData[index].checked = !this.villageData[index].checked;
                this.checkVillageCommon();
            },
            checkVillageCommon() {
                let arr = [];//小区ids
                this.villageData.forEach((item, index) => {
                    if (item.checked == true) {
                        arr.push(item.villageId);
                    }
                });
                const that = this;
                $.ajax({
                    url: this.url + "/api-public/opetower/towerByVillageId?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(arr),
                    contentType: "application/json",
                    type: "post",
                    success: function (res) {
                        console.log(res)
                        that.towerData = Object.values(res).flat(Infinity);
                    }
                })
            },
            checkVillage(index) {//小区全选取消
                if (index == 1) {//全选
                    this.villageData.forEach((item, index) => {
                        item.checked = true;
                    })
                    this.checkVillageCommon();

                } else {//取消全选
                    this.villageData.forEach((item, index) => {
                        item.checked = false;
                    })

                }
                this.towerData = [];
                this.unitData = [];
                this.houseData = [];
            },
            checkXiaoQu() {
                const that = this;
                let tenementId = JSON.parse(localStorage.getItem("user")).id;
                const data = {
                    access_token: localStorage.getItem("token")
                }
                $.ajax({
                    url: this.url + "/api-public/opevillage/findAllOpeVillage",
                    data: data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.villageData = res.data;
                        }
                    }
                })
            },
            back() {
                this.$router.go(-1);
            },
            houseCheckALl() {
                this.houseData.forEach((item, index) => {
                    item.checked = true;
                })
            },
            houseReverseAll() {

                this.houseData = [];

            },
            unitCheckALl() {//全选单元
                this.unitData.forEach((item, index) => {
                    item.checked = true;
                })

                let arr = [];
                this.unitData.forEach((item, index) => {
                    arr.push(item.unitId);
                });

                const that = this;
                $.ajax({
                    url: that.url + "/api-public/findByUnitId/list?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(arr),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        that.houseData = Object.values(res).flat(Infinity);
                        console.log(that.houseData)
                    }
                });
            },
            unitReverseAll() {//取消全选单元
                this.unitData.forEach((item, index) => {
                    item.checked = false;
                })
                this.houseData = [];
            },
            send() {
                this.loading = true;
                let towerData = this.towerData.filter(item => item.bon == true) || [];
                let towerIds = [];
                let towerNums = [];
                towerData.forEach((item, index) => {
                    towerIds.push(item.towerId);
                    towerNums.push(item.towerNum);
                })
                let unitData = this.unitData.filter(item => item.checked == true) || [];
                let unitCards = [];
                unitData.forEach((item, index) => {
                    unitCards.push(item.unitId);
                })
                let houseData = this.houseData.filter(item => item.checked == true) || [];

                let houseIds = [];
                houseData.forEach((item, index) => {
                    houseIds.push(item.houseId);
                })
                let villageId = this.towerData[0].villageId;

                const that = this;

                const data = {
                    "busiOrderDetail": {
                        villageId,
                        towerIds,
                        towerNums,
                        unitCards,//单元id数组
                        houseIds,//户室id数组
                    }
                }
                $.ajax({
                    url: that.url + "/api-public/busiorder/save?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(data),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        that.loading = false;
                        that.dis = true;
                        that.$message({
                            message: res.resp_msg,
                            type: 'success'
                        });

                    }
                });

            },
            tabHS(index) {//点击切换户室
                this.houseData[index].checked = !this.houseData[index].checked;
            },
            checkHS(index) {//根据单元查户室
                this.unitData[index].checked = !this.unitData[index].checked;
                let arr = [];
                this.unitData.forEach((item, index) => {
                    if (item.checked == true) {
                        arr.push(item.unitId);
                    }
                });

                const that = this;
                $.ajax({
                    url: that.url + "/api-public/findByUnitId/list?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(arr),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        that.houseData = Object.values(res).flat(Infinity);
                        console.log(that.houseData)
                    }
                });
            },
            checkALl() {//全选
                this.towerData.forEach((item, index) => {
                    item.bon = true;
                })
                //全选直接传this.towerData
                var _this = this;
                let towerIds = [];
                _this.towerData.forEach((item, index) => {
                    towerIds.push(item.towerId)
                });
                $.ajax({
                    url: this.url + "/api-public/opeunit/unitByTowerId?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(towerIds),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        console.log(res)
                        _this.unitData = Object.values(res).flat(Infinity);
                    }
                });
            },
            reverseAll() {//取消全选
                this.towerData.forEach((item, index) => {
                    item.bon = false;
                });
                //取消传空
                this.unitData = [];
                this.houseData = [];
            },
            check(index, towerId, row) {
                this.towerData[index].bon = !this.towerData[index].bon;
                //将选中的towerId 放进towerIds数组中
                let arr = [];
                this.towerData.forEach((item, index) => {
                    if (item.bon == true) {
                        arr.push(item.towerId);
                    }
                });
                var _this = this;
                $.ajax({
                    url: this.url + "/api-public/opeunit/unitByTowerId?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(arr),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        _this.unitData = Object.values(res).flat(Infinity);
                    }
                });
            }, checkUnit(index, unitId, row) {
                this.unitData[index].bon = !this.unitData[index].bon;
                //将选中的towerId 放进towerIds数组中
                let arr = [];
                this.unitData.forEach((item, index) => {
                    if (item.bon == true) {
                        arr.push(item.unitId);
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .content_main {
        padding-top: 20px;
    }

    .create_header > ul .active {
        background: green;
        color: #fff;
    }

    .create_content > ul > div .active1 {
        background: green;
        color: #fff;
    }

    .content {
        padding: 20px 111px;
        background: #fff;
        width: 1200px;
        min-height: 400px;
        margin: 0 auto;
    }

    .create_header {
        border-bottom: 1px dashed #e5e5e5;
        padding-bottom: 10px;
    }

    .create_header ul {
        margin-top: 15px;
    }

    .create_header ul li {
        cursor: pointer;
        background: #d9d9d9;
        text-align: center;
        padding: 5px 15px;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
        color: #666;
        display: inline-block;
    }

    .create_btn {
        margin: 15px 0;
        display: flex;
        justify-content: center;
    }

    .create_form {
        padding-left: 20px;
        margin-top: 10px;
    }

    .create_content ul {
        margin-bottom: 20px;
        max-height: 500px;
        overflow-y: scroll;
        padding: 10px;
        min-width: 300px;
        border-radius: 5px;
        border: 1px solid #999;

        h5 {
            margin-bottom: 10px;
        }
    }

    .create_content ul li {
        cursor: pointer;
        overflow: hidden;
        background: #d9d9d9;
        padding: 5px 10px;
        color: #666;
        margin-right: 10px;
        display: inline-block;
    }

    .create_content ul li p {
        text-align: center;
        margin: 5px 0;
    }
</style>